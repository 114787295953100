import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useGlobalDrawer } from "@/core/context/GlobalDrawerProvider"
import { useLabel, useLabels } from "@/core/context/LabelsContext"
import { useProductsAdminLearnMode } from "@/core/context/ProductsAdminLearnModeContext"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import { isProductLevelRoute, useStartViewAsMember } from "@/core/route/util/routeUtils"
import { ExperienceAdminDropdownFragment$key } from "@/experience/admin/dropdown/__generated__/ExperienceAdminDropdownFragment.graphql"
import ManageAppsButton from "@/experience/admin/dropdown/manage-apps/ManageAppsButton"
import LearnModeSwitch from "@/experience/components/LearnModeSwitch"
import CreateInvitationButton from "@/invitation/create/button/CreateInvitationButton"
import CloneProductButton from "@/product/clone/button/CloneProductButton"
import ProductDeleteButton from "@/product/delete/button/ProductDeleteButton"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoDivider, DiscoIcon } from "@disco-ui"
import DiscoDropdownItem from "@disco-ui/dropdown/DiscoDropdownItem"
import DiscoMoreActionsDropdown from "@disco-ui/dropdown/DiscoMoreActionsDropdown"
import { ArrayUtils } from "@utils/array/arrayUtils"
import usePermissions from "@utils/hook/usePermissions"
import { TestIDProps } from "@utils/typeUtils"
import { graphql, useFragment } from "react-relay"
import { generatePath, useHistory } from "react-router-dom"

interface ExperienceAdminDropdownProps extends TestIDProps {
  productKey: ExperienceAdminDropdownFragment$key
  moreActionsButtonClassName?: string
  rotateIcon?: boolean
  handleRefetch?: () => void
}

function ExperienceAdminDropdown(props: ExperienceAdminDropdownProps) {
  const {
    testid = "ExperienceAdminDropdown",
    productKey,
    moreActionsButtonClassName,
    rotateIcon,
  } = props

  const product = useExperienceAdminDropdownFragment(productKey)
  const { manageMemberItems, manageProductItems, manageViewerModeItems } =
    useExperienceAdminDropdownItems(props)
  const { isViewerInLearnModeForProduct } = useProductsAdminLearnMode()
  const isInLearnMode = product ? isViewerInLearnModeForProduct(product.id) : false

  // return learn mode switch if in learn mode
  if (isInLearnMode) {
    return (
      <DiscoMoreActionsDropdown
        testid={`${testid}-${product.slug}-dropdown`}
        moreActionsButtonClassName={moreActionsButtonClassName}
        rotateIcon={rotateIcon}
      >
        <LearnModeSwitch productKey={product} />
      </DiscoMoreActionsDropdown>
    )
  }

  if (!manageProductItems.length) return null

  return (
    <DiscoMoreActionsDropdown
      testid={`${testid}-${product.slug}-dropdown`}
      moreActionsButtonClassName={moreActionsButtonClassName}
      rotateIcon={rotateIcon}
    >
      {manageMemberItems}
      {manageProductItems}
      {manageViewerModeItems}
    </DiscoMoreActionsDropdown>
  )
}

export default ExperienceAdminDropdown

export function useExperienceAdminDropdownItems(
  props: Pick<ExperienceAdminDropdownProps, "testid" | "productKey" | "handleRefetch">
) {
  const { testid = "ExperienceAdminDropdown", productKey, handleRefetch } = props
  const settingsDrawer = useGlobalDrawer("experienceSettings")
  const certificatesDrawer = useGlobalDrawer("certificates")
  const history = useHistory()
  const activeOrganization = useActiveOrganization()!
  const product = useExperienceAdminDropdownFragment(productKey)
  const productPermissions = usePermissions(product)
  const orgPermissions = usePermissions(activeOrganization)
  const itemsTestId = `${testid}.${product.slug}`
  const labels = useLabels()
  const PRODUCT_REPORTS = generatePath(ROUTE_NAMES.ADMIN.INSIGHTS.PRODUCT, {
    productSlug: product.slug,
  })
  const { isViewerInLearnModeForProduct } = useProductsAdminLearnMode()
  const startViewAsMember = useStartViewAsMember()
  const memberLabel = useLabel("admin_member")

  const classes = useStyles()

  // Only show the dropdown if the product is a course
  if (product.type !== "course")
    return { manageMemberItems: [], manageProductItems: [], manageViewerModeItems: [] }

  const showCloneItem =
    orgPermissions.has("products.create") && !isViewerInLearnModeForProduct(product.id)
  const showDeleteItem =
    orgPermissions.has("products.archive") && !isViewerInLearnModeForProduct(product.id)
  const showLearnModeItem =
    product.adminCanLearnMode && productPermissions.has("learn_mode.enter")

  const manageMemberItems = [
    ...ArrayUtils.spreadIf(
      <DiscoDropdownItem
        key={`${itemsTestId}.members`}
        icon={<DiscoIcon icon={"user"} />}
        to={generatePath(ROUTE_NAMES.PRODUCT.MEMBERS.LIST, {
          productSlug: product.slug,
        })}
        title={labels.product_member.plural}
        testid={`${itemsTestId}.Members`}
      />,
      productPermissions.has("members.manage")
    ),

    ...ArrayUtils.spreadIf(
      <CreateInvitationButton
        key={`${itemsTestId}.create-invitation`}
        productId={product.id}
      >
        {(createInvitationButtonnProps) => (
          <DiscoDropdownItem
            key={`${itemsTestId}.send-invite`}
            title={`Invite ${labels.product_member.plural}`}
            icon={<DiscoIcon icon={"user-add"} />}
            {...createInvitationButtonnProps}
            testid={`${itemsTestId}.send-invite`}
          />
        )}
      </CreateInvitationButton>,
      productPermissions.has("invitations.manage")
    ),

    ...ArrayUtils.spreadIf(
      <DiscoDivider
        key={`${itemsTestId}.member-items.divider`}
        className={classes.divider}
      />,
      productPermissions.has("experience_settings.manage") ||
        productPermissions.has("members.manage") ||
        productPermissions.has("registration.manage") ||
        productPermissions.has("certificate_templates.manage") ||
        productPermissions.has("apps.manage") ||
        showCloneItem ||
        showDeleteItem
    ),
  ]

  const manageProductItems = [
    // Settings
    ...ArrayUtils.spreadIf(
      <DiscoDropdownItem
        key={`${itemsTestId}.Settings`}
        onClick={() =>
          settingsDrawer.open({
            drawerExperienceId: product.id,
            experienceSettingsTab: "details",
          })
        }
        title={"Settings"}
        icon={<DiscoIcon icon={"settings"} />}
        testid={`${itemsTestId}.Settings`}
      />,
      productPermissions.has("experience_settings.manage")
    ),

    // Reports
    ...ArrayUtils.spreadIf(
      <DiscoDropdownItem
        key={`${itemsTestId}.Reports`}
        to={PRODUCT_REPORTS}
        title={"Reports"}
        icon={<DiscoIcon icon={"chart-bar"} />}
        testid={`${itemsTestId}.Reports`}
      />,
      productPermissions.has("members.manage")
    ),

    // Registration Page
    ...ArrayUtils.spreadIf(
      <DiscoDropdownItem
        key={`${itemsTestId}.RegistrationPage`}
        to={generatePath(ROUTE_NAMES.PRODUCT.REGISTRATION.ROOT, {
          productSlug: product.slug,
        })}
        title={"Registration Page"}
        icon={<DiscoIcon icon={"ticket"} />}
        testid={`${itemsTestId}.RegistrationPage`}
      />,
      productPermissions.has("registration.manage")
    ),

    // Certificates
    ...ArrayUtils.spreadIf(
      <DiscoDropdownItem
        key={`${itemsTestId}.Certificates`}
        onClick={() =>
          certificatesDrawer.open({ drawerCertificatesProductId: product.id })
        }
        title={"Certificates"}
        icon={<DiscoIcon icon={"achievement"} />}
        testid={`${itemsTestId}.Certificates`}
      />,
      productPermissions.has("certificate_templates.manage")
    ),

    // Manage Apps
    ...ArrayUtils.spreadIf(
      <ManageAppsButton key={`${itemsTestId}.manage-apps`} productId={product.id}>
        {(manageAppsButtonnProps) => (
          <DiscoDropdownItem
            key={`${itemsTestId}.manage-apps`}
            title={"Manage Apps"}
            icon={<DiscoIcon icon={"puzzle-piece"} />}
            {...manageAppsButtonnProps}
            testid={`${testid}-${product.slug}.manage-apps`}
          />
        )}
      </ManageAppsButton>,
      productPermissions.has("apps.manage")
    ),

    // Clone
    ...ArrayUtils.spreadIf(
      <CloneProductButton key={`${itemsTestId}.clone`} productId={product.id}>
        {(cloneButtonProps) => (
          <DiscoDropdownItem
            title={"Clone"}
            icon={<DiscoIcon icon={"iconsax.copy"} />}
            {...cloneButtonProps}
            testid={`${itemsTestId}.clone`}
          />
        )}
      </CloneProductButton>,
      showCloneItem
    ),

    // Delete
    ...ArrayUtils.spreadIf(
      <ProductDeleteButton
        key={`${itemsTestId}.delete`}
        product={{
          id: product.id,
          type: product.type,
          name: product.name,
          slug: product.slug,
        }}
        onDelete={() => {
          if (handleRefetch) {
            handleRefetch()
          } else if (isProductLevelRoute(location.href, { productSlug: product.slug })) {
            history.push(ROUTE_NAMES.COMMUNITY.EXPERIENCES.COURSES.UPCOMING)
          }
        }}
      >
        {(buttonProps) => (
          <DiscoDropdownItem
            {...buttonProps}
            title={"Delete"}
            icon={<DiscoIcon icon={"trash"} />}
            testid={`${itemsTestId}.delete`}
          />
        )}
      </ProductDeleteButton>,
      showDeleteItem
    ),

    ...ArrayUtils.spreadIf(
      <DiscoDivider key={`${itemsTestId}.product-items.divider`} />,
      productPermissions.has("view_as.member") || showLearnModeItem
    ),
  ]

  const manageViewerModeItems = [
    // Preview as Member
    ...ArrayUtils.spreadIf(
      <DiscoDropdownItem
        onClick={startViewAsMember}
        title={`Preview as ${memberLabel.singular}`}
        rightIcon={<DiscoIcon icon={"iconsax.export-2"} />}
        testid={`${itemsTestId}.view-as-member`}
      />,
      productPermissions.has("view_as.member")
    ),

    // Learn Mode
    ...ArrayUtils.spreadIf(
      <LearnModeSwitch testid={itemsTestId} productKey={product} />,
      showLearnModeItem
    ),
  ]

  return { manageMemberItems, manageProductItems, manageViewerModeItems }
}

const useStyles = makeUseStyles(() => ({
  divider: {
    height: "2px", // Need to do this or else the divider sometimes disappears
    transform: "scaleY(0.5)",
  },
}))

function useExperienceAdminDropdownFragment(
  productKey: ExperienceAdminDropdownFragment$key
) {
  return useFragment<ExperienceAdminDropdownFragment$key>(
    graphql`
      fragment ExperienceAdminDropdownFragment on Product {
        id
        slug
        type
        name
        adminCanLearnMode
        ...LearnModeSwitchFragment
        ...usePermissionsFragment
      }
    `,
    productKey
  )
}
